export default function Contact() {
  return (
    <>
      <div className="intro-header">
        <span className="heading">Contact Us</span>
      </div>
      <div className="container">
        <div className="contact-form-grid w-layout-grid">
          <div className="contact-form-wrapper">
            {/* <div className="contact-form-heading-wrap"> */}
            <div className="paragraph-light">
              <p>
                At <b>100 TECH</b>, we are eager to turn your software
                ideas into reality. Whether you're embarking on a new project,
                seeking specialized advice, or exploring advanced training
                opportunities, we're here to assist.
              </p>
              <p>
                <b>Email Us:</b> For all your inquiries, project discussions,
                and feedback, please feel free to contact us at{" "}
                <a href="mailto:info@100tech.services" className="emailLink">
                  info@100tech.services
                </a>
                .
              </p>
              <p>
                Our team is dedicated to providing prompt and comprehensive
                responses to ensure your needs are met with the utmost
                professionalism and expertise.
              </p>
            </div>
          </div>
          <div className="details">
            <div className="details-wrap">
              <p>
                <b> Our Office</b>
              </p>
              <div className="paragraph-light">
                <p>
                  Dubai Media City Building 5<br></br>
                  Dubai, UAE
                </p>
              </div>
            </div>
            <div className="details-wrap">
              <p>
                <b>Working hours</b>
              </p>
              <div className="paragraph-light">
                <p>9AM - 5PM, Monday to Friday </p>
              </div>
            </div>
            <div className="details-wrap">
              <p>
                <b>Email</b>
              </p>
              <div className="paragraph-light">
                {" "}
                <a href="mailto:info@100tech.services" className="emailLink">
                  {" "}
                  info@100tech.services{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
