import { Routes, Route, Navigate} from "react-router-dom";
import Layout from "./Layout/Layout"
import About from "./About"
import Contact from "./Contact"
import Services from "./Services"
import Jobs from "./Jobs"
import Vacancy from "./Vacancy"

export default function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={ <Navigate replace to="/about" />}/>
        <Route path="/" element={<Layout />}>
          <Route path="services" element={<Services />} />
          <Route path="about" index element={<About />} />
          <Route path="contacts" element={<Contact />} />
          <Route exact path="jobs" element={<Jobs />} />
          <Route path="jobs/rust-dev" element={<Vacancy/>}/>
          <Route path="" element={<About />}/>
          <Route path="*" element={<About />}/>

        </Route>
      </Routes>
    </div>
  );
}