import rustlogo from "./images/rustlogo.png";

export default function Vacancy() {
  return (
    <>
      <div className="intro-header">
        <span className="heading">Join our team</span>
      </div>
      <div className="container">
        <div className="vacancy-wrap vacancy">
          <div>
            <h3 className="vacancy-name">Senior Rust Software Developer</h3>
            <p className="paragraph-light small-vacancy-text">
              100% remote
            </p>
            <p className="paragraph-light small-vacancy-text">
            optional relocation to Dubai
            </p>
          </div>
          <div className="rustLogo-container">
            <img src={rustlogo} className="rustLogo" />
          </div>
        </div>
        <div className="vacancy-block">
          <div className="paragraph-light">
            <p>
              <b>Experience</b>: 5+ years in backend development.
            </p>

            <p>
              <b>Job description</b>: We are seeking a talented and experienced
              Rust Developer to join our dynamic team. The successful candidate
              will be instrumental in developing a high-performance search
              engine tailored for airline, railway, and bus ticketing systems.
              This is a unique opportunity to contribute to a project that
              combines cutting-edge technology with a critical sector of the
              travel industry.
            </p>

            <p>
              <b>Key responsibilites</b>:
              <ul>
                <li>
                  <b>Development</b>: Play a pivotal role in the development of
                  a robust search engine using Rust. Ensure the system is
                  optimized for speed, efficiency, and scalability to handle
                  large volumes of data and queries.
                </li>
                <li>
                  <b>Collaboration and Teamwork</b>: Work closely with a team of
                  developers, designers, and product managers. Participate in
                  code reviews, provide constructive feedback, and collaborate
                  on problem-solving to enhance the overall quality of the
                  software.
                </li>
                <li>
                  <b>Quality Assurance</b>: Uphold high standards of quality by
                  writing clean, efficient, and maintainable code. Participate
                  in testing processes to identify and fix bugs, ensuring the
                  reliability and stability of the application.
                </li>
                <li>
                  <b>Innovation and Improvement</b>: Continuously research and
                  implement best practices in backend development and Rust
                  programming. Suggest improvements to enhance performance,
                  reliability, and usability of the search engine.
                </li>
              </ul>
            </p>

            <p>
              <b>Qualifications</b>:
              <ul>
                <li>
                  <b>Experience</b>: Minimum of 5 years of experience in backend
                  development. While direct professional experience in Rust is
                  not required, a solid understanding and knowledge of the
                  language are essential.
                </li>
                <li>
                  <b>Technical Skills</b>: Proficient in backend development
                  languages and frameworks. Strong analytical and
                  problem-solving skills, with a focus on performance
                  optimization and scalability.
                </li>
                <li>
                  <b>Communication Skills</b>: Excellent verbal and written
                  communication skills. Ability to effectively communicate
                  technical concepts to team members and stakeholders.
                </li>
                <li>
                  <b>Education</b>: Bachelor’s or Master’s degree in Computer
                  Science, Software Engineering, or a related field is
                  preferred.
                </li>
              </ul>
            </p>

            <p>
              <b>Benefits</b>:
              <ul>
                <li>
                  Fully remote work environment with the option for relocation
                  to Dubai.
                </li>
                <li>Competitive salary and benefits package.</li>
                <li>
                  Opportunity to work on a groundbreaking project in a
                  fast-paced, innovative environment.
                </li>
                <li>Professional growth and development opportunities.</li>
              </ul>
            </p>

            <p>
              <b>How to apply</b>: Please submit your resume to{" "}
              <a href="mailto:hr@100tech.services" className="emailLink">
                {" "}
                hr@100tech.services
              </a>
              , along with a cover letter highlighting your experience in
              backend development and your knowledge of Rust. Include any
              relevant projects or contributions to open-source communities.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
