import support from "./images/support.svg";
import consulting from "./images/consulting.svg";
import development from "./images/development.svg";
import training from "./images/training.svg";

export default function Services() {
  return (
    <>
      <div className="intro-header">
        <span className="heading">Our services</span>
      </div>
      <div className="container">
        <div className="motto-wrap">
          <p className="offer-paragraph">
            {" "}
            Interested in elevating your tech experience? Reach out to us at{" "}
            <a href="mailto:info@100tech.services" className="emailLink">
              {" "}
              info@100tech.services
            </a>{" "}
          </p>
          <p className="offer-paragraph">
            {" "}
            Let's make technology work for you!{" "}
          </p>
        </div>
        <div className="section">
          <div className="w-layout-grid our-services-grid wf-grid services">
            <div className="service-block">
              <img src={development} className="service-image" />
              <div className="paragraph-bigger">Software development</div>
              <div className="paragraph-light">
                Transforming Ideas into Digital Excellence: Our team of expert
                developers specializes in crafting custom software solutions
                tailored to your unique business needs. From innovative apps to
                robust software systems, we ensure seamless integration and
                future-proof technology to propel your business forward.
              </div>
            </div>
            <div className="service-block">
              <img src={consulting} className="service-image" />
              <div className="paragraph-bigger">Consulting</div>
              <div className="paragraph-light">
                Navigate the Digital Landscape with Confidence: Our IT
                consulting services provide strategic guidance to optimize your
                technology infrastructure, streamline processes, and enhance
                operational efficiency. We offer expert insights and innovative
                solutions to keep you ahead in the ever-evolving tech world.{" "}
              </div>
            </div>
            <div className="service-block">
              <img src={support} className="service-image" />
              <div className="paragraph-bigger">Support</div>
              <div className="paragraph-light">
                Reliable IT Support for Uninterrupted Business: We provide
                comprehensive support services to ensure your IT systems run
                smoothly. Our dedicated team offers quick resolutions, proactive
                maintenance, and 24/7 assistance, minimizing downtime and
                keeping your business operations seamless.{" "}
              </div>
            </div>

            <div className="service-block">
              <img src={training} className="service-image" />
              <div className="paragraph-bigger">Training</div>
              <div className="paragraph-light">
                Advanced IT Training for Mastery and Innovation: Dive into the
                depths of cutting-edge technology with our specialized, advanced
                training. We focus exclusively on enhancing your team's
                expertise in the latest software and IT trends through in-depth,
                interactive sessions. Equip your staff with the skills and
                knowledge necessary to stay ahead of the curve, ensuring a
                competitive edge through technological proficiency and
                innovation.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
