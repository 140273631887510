
import logo from './images/logo.png';

export default function Footer() {
    return (
        <>
            <div className="divider"></div>
            <div className="footer-wrap paragraph-light">
                <img src={logo} className="logo-footer" />
                <div>100 Tech Services FZ-LLC 2023</div>
            </div>
        </>
    )
}