
import "./index.css";
import {Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../images/logo.png'; 
import {useState, useEffect} from 'react';
import Footer from "../Footer";

export default function Layout() {

  let navigate = useNavigate(); 

  const [isOpen, setOpen] = useState(false);
  const {pathname} = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [ pathname ]);
  

    return (
      <>
      <nav className="navigation navbar"> 
        <div className="navigation-wrap navbar navbar-container container">
          <div className="w-nav-brand">
            <img src={logo} className="logoNav" alt="logo" onClick={()=> navigate("/about")} />
          </div> 
          <input type="checkbox" name="" id="" onChange={() => setOpen(!isOpen)} checked={isOpen}/>
          <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
          </div>
          <div className={"menu-wrap " + (isOpen ? 'open' : 'closed')}>
          <ul className="menu">
            
          <li className="navigation-item">
              <Link to="/about" className={"w-nav-link "+ (pathname == "/about" ? "active" : "")} >About</Link>
            </li>
            <li className="navigation-item">
              <Link to="/services" className={"w-nav-link "+ (pathname == "/services" ? "active" : "")}>Services</Link>
            </li>
            <li className="navigation-item">
              <Link to="/jobs" className={"w-nav-link " + (pathname == "/jobs" ? "active" : "")}>Jobs</Link>
            </li>
            <li className="navigation-item">
              <Link to="/contacts" className={"w-nav-link "+ (pathname == "/contacts" ? "active" : "")}>Contacts</Link>
            </li>
          </ul>
          </div>
        </div>
      </nav>
      <Outlet />
      <Footer/>
      </>
    );
  }