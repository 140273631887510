import { Link } from "react-router-dom";
import rustlogo from "./images/rustlogo.png";

export default function Jobs() {
  return (
    <>
      <div className="intro-header">
        <span className="heading">Join our team</span>
      </div>
      <div className="container">
        <div className="vacancy-wrap">
          <div>
            <div>
              <Link to={"rust-dev"} className="navigation-item vacancy-link">
                Senior Rust Software Developer
              </Link>
            </div>
            <p className="paragraph-light small-vacancy-text">100% remote</p>
            <p className="paragraph-light small-vacancy-text">
              optional relocation to Dubai
            </p>
          </div>
          <div className="rustLogo-container">
            <img src={rustlogo} className="rustLogo" />
          </div>
        </div>
      </div>
    </>
  );
}
