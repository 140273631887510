

export default function About() {

    return (
        <>
            <div className="intro-header">
                <span className="heading">About Us</span>
            </div>
            <div className="container">
            <div className="motto-wrap">
                        <div>
                            <span className="heading-2 company-name">  100 TECH</span>
                            <p className="heading-3"> 
                            Pioneering Software Solutions with Cutting-Edge Expertise
                            </p>
                        </div>
                    </div>
                    <div className="about-story-wrap">
                    <h3 className="sub-heading"> Our Mission </h3>
                    <p>
                        At <span className="company-name">100 TECH</span>, we are committed to developing exceptional software that meets the evolving needs of businesses. We blend innovation with tried-and-true methodologies to deliver robust, reliable, and efficient software solutions.
                    </p>
                    <h3>Our Expertise</h3>
                    <p>
                        <ul>
                            <li>Languages and Technologies: Our team is not just proficient; we're passionate about programming languages and technologies. We specialize in Rust and Clojure/Clojurescript, bringing modern functional programming paradigms to the forefront of software development. Our expertise extends to Haskell, Erlang, Scala, JavaScript, Ruby, and Python, ensuring versatility and adaptability to any project.</li>
                            <li>High-Performance Backend Systems: We excel in creating high-load backend solutions. Our systems are designed to handle massive volumes of data and traffic, ensuring your business operates smoothly under any load.</li>
                            <li>Distributed and Fault-Tolerant Systems: Reliability is key in today's interconnected world. We build systems that not only stand the test of time but also the unforeseen challenges of distributed computing environments.</li>
                            <li>Web and Mobile App Development: Our team creates engaging, user-friendly websites and mobile applications, focusing on delivering a seamless user experience across all platforms.</li>
                        </ul>
                    </p>
                    <h3>Why Choose Us?</h3>
                    <p>
                        <ul>
                            <li>Experienced Team: Our team comprises industry veterans with years of experience in developing and managing complex software projects.</li>
                            <li>Customer-Centric Approach: We listen to our clients and tailor solutions that not only meet but exceed their expectations.</li>
                            <li>Innovative Solutions: Staying ahead of the curve, we continuously explore and incorporate new technologies and methodologies to provide the best possible service.</li>
                        </ul>

                        <h3>Contact Us</h3>
                        <p>Let's discuss how we can help your business thrive through technology. Reach out to us at <a href="mailto:info@100tech.services" className="emailLink"> info@100tech.services</a>.   ‍
                        </p>
                    </p>
                </div>

            </div>
        </>
    )

}